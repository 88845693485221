@import '~antd/dist/antd.less';

.navbar {
  padding: 0 30px 0 10px;

  .ant-col {
    height: fit-content;
  }

  .ant-btn-link {
    color: @text-color;
  }

  .user-link {
    display: flex;
    align-items: center;
    height: 40px;

    a {
      color: black;
    }
  }
}

@primary-color: #fec806;@highlight-color: #fec806;@text-color: #414141;@white: #ffffff;@red-base: #f5474c;@lightgray: #dadce2;@body-background: #f6f8f9;@layout-body-background: #f6f8f9;@layout-header-background: #ffffff;@btn-primary-color: #414141;@btn-text-shadow: none;@font-size-base: 15px;@font-size-sm: 13px;@form-item-margin-bottom: 10px;@card-radius: 10px;@border-radius-base: 5px;