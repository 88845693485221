.upload-image-container {
  .image-container {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 8px;
    position: relative;
    width: 200px;
    height: 200px;
  }

  .image-container .overlay {
    background: black;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    transition: opacity 0.5s;
  }

  .image-container:hover .overlay {
    opacity: 0.7;
    transition: opacity 0.5s;
  }

  .image-profile-picture {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .icon-upload-image {
    cursor: pointer;
    color: white;
    padding: 6px;
    font-size: 20px;
  }

  .icon-upload-image-container {
    position: absolute;
    color: white;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .upload-image-button {
    text-align: center;
  }
}

.image-crop-container {
  img {
    width: 100%;
  }
}

@primary-color: #fec806;@highlight-color: #fec806;@text-color: #414141;@white: #ffffff;@red-base: #f5474c;@lightgray: #dadce2;@body-background: #f6f8f9;@layout-body-background: #f6f8f9;@layout-header-background: #ffffff;@btn-primary-color: #414141;@btn-text-shadow: none;@font-size-base: 15px;@font-size-sm: 13px;@form-item-margin-bottom: 10px;@card-radius: 10px;@border-radius-base: 5px;