@import '~antd/dist/antd.less';

.main-UI-container {
  margin-bottom: 30px;

  .title-container {
    margin: 40px 0 30px @margin-lg !important;
  }
}

@primary-color: #fec806;@highlight-color: #fec806;@text-color: #414141;@white: #ffffff;@red-base: #f5474c;@lightgray: #dadce2;@body-background: #f6f8f9;@layout-body-background: #f6f8f9;@layout-header-background: #ffffff;@btn-primary-color: #414141;@btn-text-shadow: none;@font-size-base: 15px;@font-size-sm: 13px;@form-item-margin-bottom: 10px;@card-radius: 10px;@border-radius-base: 5px;