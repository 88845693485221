.manage-artist-container {
  .manage-artist-title {
    margin: 30px 0 0 30px;
  }

  .ant-card-body {
    padding-top: 0;
  }

  .ant-table-cell {
    background-color: white;
  }

  .add-artist-opener {
    width: 60px;
    height: 60px;
    font-size: 25px;
  }
}

@primary-color: #fec806;@highlight-color: #fec806;@text-color: #414141;@white: #ffffff;@red-base: #f5474c;@lightgray: #dadce2;@body-background: #f6f8f9;@layout-body-background: #f6f8f9;@layout-header-background: #ffffff;@btn-primary-color: #414141;@btn-text-shadow: none;@font-size-base: 15px;@font-size-sm: 13px;@form-item-margin-bottom: 10px;@card-radius: 10px;@border-radius-base: 5px;